import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import TextBlock from 'components/flex/Text/TextBlock'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const StyledTextImageSecond = styled.div`
  background-color: rgb(50, 50, 50, 0.05);
  mix-blend-mode: normal;
`

const StyledTextBlock = styled(ParseContent)`
  & h1 {
    color: ${(props) => props.theme.color.face.main};
    @media (min-width: 992px) {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 1.5rem;
    }
  }

  & p {
    font-family: ${(props) => props.theme.font.family.main};
    color: ${(props) => props.theme.color.face.main};
  }
`

const Image = styled(Plaatjie)`
  @media (max-width: 991px) {
    width: 150px;
    height: auto;
  }
`

interface TextImageSecondProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextImageSecond
}

interface PositionProps {
  [key: string]: any
}

const TextImageSecond: React.FC<TextImageSecondProps> = ({ fields }) => {
  const leftPosition: PositionProps = {
    left: <Image alt="" image={fields?.image} />,
    right: <StyledTextBlock content={fields.description} />,
  }

  const rightPosition: PositionProps = {
    right: <Image alt="" image={fields?.image} />,
    left: <StyledTextBlock content={fields.description} />,
  }

  return (
    <StyledTextImageSecond>
      <div className="container pt-3 pt-lg-0 pb-lg-5">
        <div className="row p-3 p-lg-5">
          <div className="col-lg-8 mb-4 mb-lg-0">
            {leftPosition[fields?.styleType || 'left']}
          </div>
          <div className="col-lg-4 text-center">
            {rightPosition[fields?.styleType || 'left']}
          </div>
        </div>
      </div>
    </StyledTextImageSecond>
  )
}

export default TextImageSecond
